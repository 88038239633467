



import { createSlice } from '@reduxjs/toolkit'

import * as api from '../api'
import * as utils from '../utils'

const logsSlice = createSlice({
	name: 'logsSlice',
	initialState: {
		dirs: [],
		bucketData: {},
		jsonStructure: {},
		logs: {},
		loading: false
	},
	reducers: {
		downloadedLogDir(state, action) {
			state.bucketData = action.payload
			return state;
		},
		buildDirStructure(state, action) {
			let jsonStructure = utils.s3ToJsonDirStructure( state.bucketData );
			
			state.jsonStructure = jsonStructure

			return state;
		},

		downloadedLogData( state, action ) {
			console.log("downloaded multiple logs!")

			let logs = action.payload

			Object.keys(logs).forEach(key => {
				if( !(key in state.logs) ) {
					state.logs[key] = {}
				}
				state.logs[key].data = logs[key].data
			});

			return state;
		},

		downloadedAllMetadata( state, action ) {
			let allMetadata = action.payload

			Object.keys(allMetadata).forEach(key => {
				if( !(key in state.logs) ) {
					state.logs[key] = {}
				}
				state.logs[key].metadata = allMetadata[key].metadata
			});

			return state;
		},

		updateLogMetadata( state, action ) {
			let payload = action.payload

			state.logs[payload.key].metadata = payload.metadata

			return state;
		},

		setLoading( state, action ) {
			state.loading = action.payload
			return state;
		}

	}
})


export const downloadLogMetadata = (key) => async( dispatch ) => {
	try {
		console.log('downloading key :', key)
		let response = await api.getLogMetadata( key )
		console.log('response :', response)

		
		return response	
	}
	catch( err ) {
		console.error(err)
	}
}

export const downloadMetadata = ( keysToFetch ) => async(dispatch) =>{
	try {
		let promises = [];
		for( const key of keysToFetch) {
            keysToFetch.push(key)
            promises.push( dispatch( downloadLogMetadata( key ) ) )
        }
	}
	catch( err ) {
		console.error(err)
	}
}

// const fetchMetadata = async( keysToFetch ) => {
// 	if( keysToFetch.length === 0 ) {
// 		return;
// 	}

// 	console.log('fetchMetadata')
// 	let promises = []
// 	for( const key of keysToFetch) {
// 		promises.push( dispatch( downloadLogMetadata( key ) ) )
// 	}

// 	let company = keysToFetch[0].split('/')[1]
// 	let location = keysToFetch[0].split('/')[2]


// 	try{
// 		let metadataArray = await Promise.all(promises);
// 		console.log('promises finished')
// 		console.log('metadataArray :', metadataArray)

// 		let data = metadataArray.map( (item, index) => {

// 			let dateStr = utils.parseDateFromKey( keysToFetch[index] )
// 			let timeStr = utils.parseTimeFromKey( keysToFetch[index] ) 

// 			return {
// 				company: company,
// 				location: location,
// 				user: item.Metadata.user,
// 				version: item.Metadata.version,
// 				date: dateStr + ' ' + timeStr,
// 			}
// 		})

// 		setLogs(data);
// 	}
// 	catch( err ) {
// 		console.error( err )
// 	}
// } 

export const getMulitpleLogs = async( keys ) => {
	try {
		let promises = []
		for( let i = 0; i < keys.length; i++ ) {
			const key = keys[i]
			promises.push( api.getLogData(key) )
		}

		let results = await Promise.all(promises)

		// Return as key value mapping
        let data = {}
        for( let i = 0; i < keys.length; i++ ) {
            const key = keys[i]
            data[key] = {}
            data[key].data = results[i]

			// console.log('data[key].data :', data[key].data)
        }

		console.log('log data downloaded')
		return data

	}
	catch( err ) {
		console.error("getLogData failed!")
		console.error(err)
	}
}



export const getLogDir = () => async (dispatch) => {
	try {
		dispatch( setLoading( true ) )

		const logDirData = await api.getLogDir()
		
		dispatch( downloadedLogDir(logDirData) )
		dispatch( buildDirStructure() )

		// Get all metadata for directories
		let allMetadata = await api.getAllMetadata( logDirData )

		let keys = Object.keys( allMetadata )
		dispatch( downloadedAllMetadata( allMetadata ) )

		// For now, download all logs when we have the metadata
		dispatch( downloadLogs( keys ) )
		
	}
	catch (err) {
		console.error(err)
	}
}

export const downloadLogs = (keys) => async (dispatch) => {
	try {
		// dispatch( setLoading( true ) )

		let data = await getMulitpleLogs(keys)
		dispatch( downloadedLogData( data ) )
		
		dispatch( setLoading( false ) )
	}
	catch( err ) {
		console.error(err)
	}
}

export const updateLog = (key, data, metadata) => async(dispatch) => {
	try{
		await api.uploadLog(key, data, metadata)

		dispatch( updateLogMetadata({key: key, data: data, metadata: metadata}) )
	}
	catch( err ) {
		console.error(err)
	}
}

export const { downloadedLogDir, buildDirStructure, downloadedLog, downloadedAllMetadata, downloadedLogData, setLoading, updateLogMetadata } = logsSlice.actions
export default logsSlice.reducer
