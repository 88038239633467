import logo from './logo.svg';
import './App.css';


import { Provider } from 'react-redux';
import { store } from './state/store'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Dashboard from './dashboard';

function App() {
  return (
		<Provider store={store}>	
				<div className="App">
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Dashboard />} />
              </Routes>
					</BrowserRouter>
				</div>
		</Provider>
	);
}

export default App;
