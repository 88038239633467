import { Buffer } from 'buffer'

async function apiPostRequest( url, data ) {
    try {
        let response = await fetch( url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        if( response.ok === false ) {
            throw( new Error( response ) );
        }

        return response.json() 
    }
    catch (err) {
        console.error(err)
    }
}

async function apiGetRequest( url ) {
    try {
        let response = await fetch( url)

        if( response.ok === false ) {
            throw( new Error( response ) );
        }

        return response.json() 
    }
    catch (err) {
        console.error(err)
    }
}

export function getLoginData(uuid) {
    const postData = {
        uuid: uuid
    }

    const data = JSON.stringify(postData);
    let hostname = '1ulijg1gh6.execute-api.eu-west-1.amazonaws.com'
    let path = '/default/activateKrakenTablet'
    let url = 'https://' + hostname + path

    return fetch( url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
    .then( response => {
        console.log(response);

        if( response.ok === false ) {
            throw( new Error( response ) );
        }
        return response.json()
    });
}

function checkFileExists( bucket, key ) {
    const postData = {
        bucket: bucket,
        key: key 
    }

    const data = JSON.stringify(postData);

    let url = 'https://hhkrzz1k2j.execute-api.eu-west-1.amazonaws.com/default/checkFileExists'

    return fetch( url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
    .then( response => {
        console.log('response :', response)
        if( response.ok === false ) {
            throw( new Error( response ) );
        }

        return response.json()
    })
    .then( data => {
        return data
    });
}

function downloadFile( bucket, key ) {
    const postData = {
        bucket: bucket,
        key: key 
    }

    const data = JSON.stringify(postData);

    let hostname = 'ckqe0wzzu3.execute-api.eu-west-1.amazonaws.com'
    let path = '/default/downloadFile'
    let url = 'https://' + hostname + path

    return fetch( url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
    .then( response => {
        if( response.ok === false ) {
            throw( new Error( response ) );
        }

        return response.json()
    })
    .then( data => {
        let bufferedData = Buffer.from( data.Body.data )
        let jsonData = JSON.parse( bufferedData.toString('utf-8') )
        return jsonData
    });
}

export async function uploadLog( key, data, metadata ) {

    let url = 'https://ffb1cohw32.execute-api.eu-west-1.amazonaws.com/default/kraken-monitor-upload-log'

    // remove 'logs/' from key as it is build in to function
    key = key.replace("logs/", "")

    let postRequestData = {
        key: key,
        data: data,
        metadata: metadata
    }

    let response = await fetch(url, {
        method: 'POST',

        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postRequestData)
    })

    console.log('response :', response)


}

export function getLocations( bucket ) {
    return downloadFile( bucket, 'locations.json')
}

export function getSensorList( bucket, location ) {
    return downloadFile( bucket, location + '/sensors.json')
}

export function getUsers( userPoolId ) {
    const postData = {
        userPoolId: userPoolId
    }

    const data = JSON.stringify(postData);
    let hostname = '8w99wul4pa.execute-api.eu-west-1.amazonaws.com'
    let path = '/default/getUserPoolUsers'
    let url = 'https://' + hostname + path

    return fetch( url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
    .then( response => {
        console.log(response);

        if( response.ok === false ) {
            throw( new Error( response ) );
        }
        return response.json()
    });
}

export function addUser( userPoolId, username, email, accessLevel ) {
    const postData = {
        userPoolId: userPoolId,
        username: username,
        userEmail: email,
        accessLevel: accessLevel,
    }

    const data = JSON.stringify(postData);
    let hostname = 'mu2lfsssdg.execute-api.eu-west-1.amazonaws.com'
    let path = '/default/addUser'
    let url = 'https://' + hostname + path

    return fetch( url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
    .then( response => {
        console.log(response);

        if( response.ok === false ) {
            throw( new Error( response ) );
        }
        return response.json()
    });
}

export function deleteUser( userPoolId, username ) {
    const postData = {
        userPoolId: userPoolId,
        username: username,
    }

    const data = JSON.stringify(postData);
    let hostname = 'epdcuesxp4.execute-api.eu-west-1.amazonaws.com'
    let path = '/default/deleteUser'
    let url = 'https://' + hostname + path

    return fetch( url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
    .then( response => {
        console.log(response);

        if( response.ok === false ) {
            throw( new Error( response ) );
        }
        return response.json()
    });
}

export function resetUserPassword( userPoolId, username ) {
    const postData = {
        userPoolId: userPoolId,
        username: username,
    }

    const data = JSON.stringify(postData);

    let hostname = 'ijwafuofyi.execute-api.eu-west-1.amazonaws.com'
    let path = '/default/resetUserPassword'
    let url = 'https://' + hostname + path

    return fetch( url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    })
    .then( response => {
        console.log(response);

        if( response.ok === false ) {
            throw( new Error( response ) );
        }
        return response.json()
    });
}

export function editUserLocations( userPoolId, username, newLocations ) {
    let url = "https://493na7t9x9.execute-api.eu-west-1.amazonaws.com/default/setUserLocations"
    
    let data = {
        username: username,
        userPoolId: userPoolId,
        locations: newLocations
    }
    console.log('data :', data)
    return apiPostRequest( url, data )
}

export function downloadOpenChecks( bucket, location ) {
    return downloadFile( bucket, location + "/open_checks.json" )
}

export function downloadClosingChecks( bucket, location ) {
    return downloadFile( bucket, location + "/closing_checks.json" )
}

export function downloadDiaryChecks( bucket, location ) {
    return downloadFile( bucket, location + "/diary_checks.json" )
}

export function downloadCompaniesFile( ) {
    return downloadFile( 'kraken', "/companies.json" )
}

export function downloadSensorDataForDate( tableName, sensorId, dateString ) {
    let queryParams = {
        ExpressionAttributeNames:{
            "#mydate": "date"
        },
        ExpressionAttributeValues: {
            ":sensorId": sensorId,
            ":date": dateString
        },
    
        KeyConditionExpression: "sensorId = :sensorId and #mydate = :date",
    };

    let index = "sensorId-date-index"

    let data = {
        tableName: tableName,
        indexName: index,
        queryParams: queryParams
    }

    // queryBb lambda function
    let url = "https://dpv521wj63.execute-api.eu-west-1.amazonaws.com/default/queryDb"

    return apiPostRequest( url, data )
}

export async function downloadLocationSettings( bucket, location ) {
    // Check if location alert settings exist, if not, use the default ones

    let locationSettingsFile = location + "/location_alert_settings.json";

    console.log('checking location file exists:')

    let exists = await checkFileExists(bucket, locationSettingsFile);

    if( !exists ) {
        console.log("No location alert settings file exists for location, using default settings instead...")
        return downloadFile( bucket, 'default_location_alert_settings.json' )
    }
    else {
        return downloadFile( bucket, locationSettingsFile)
    }
}

export async function getLogDir()
{
    let url = "https://2ij5jmttr3.execute-api.eu-west-1.amazonaws.com/default/kraken-monitor-get-log-dir"
    return apiGetRequest(url);
}

export async function getLogMetadata( key ) 
{
    let url = "https://qlwiszcv46.execute-api.eu-west-1.amazonaws.com/default/kraken-monitor-get-log-metadata"
    
    let data = {
        bucket: 'kraken-monitor',
        key: key,
    }

    return apiPostRequest(url, data);
}

export async function getLogData( key ) {
    let url = "https://ajkubykzai.execute-api.eu-west-1.amazonaws.com/default/kraken-monitor-get-log-data"

    let data = {
        bucket: 'kraken-monitor',
        key: key,
    }

    try {
        let responseData = await apiPostRequest(url, data)
        
        let bufferedData = Buffer.from( responseData.Body.data )

        let logData = bufferedData.toString('utf-8')
        return logData
    }
    catch (err) {
        console.error(err)
        return null
    }
}

/**
 * 
 * @param {*} bucketData - the raw response from getLogDir (listBucketV2?)
 * @returns json with key - metadata mappings
 */
export const getAllMetadata = async(bucketData) => {
	let paths = bucketData.Contents.map( item => {
        return { 
            key: item.Key,
        }
    })

	let logFileKeys = paths.filter( item => item.key.includes('.txt')).map( item => item.key)

	let promises = []
	for( const key of logFileKeys) {
		promises.push( getLogMetadata( key ) )
	}

	try {
		let allMetadata = await Promise.all(promises)

        // Return as key value mapping
        let data = {}
        for( let i = 0; i < logFileKeys.length; i++ ) {
            const key = logFileKeys[i]
            data[key] = {}
            data[key].metadata = allMetadata[i].Metadata
        }
		return data
	}
	catch (err) {
		console.error('Get all metadata failed!')
		console.error(err)
	}
}