
const Log = (props) => {
    const log = props.log

    let key = Object.keys(log)[0];
    let text = log[key].data

    const setStatusResolved = (e) => {
        console.log("setting log resolved...")

        if( props.resolve ) {
            props.resolve(key);
        }
        else {
            console.warn("please set resolve callback on log")
        }
        e.stopPropagation()
    }

    const setStatusUnresolved = (e) => {
        console.log("setting log unresolved...")

        if( props.unresolve ) {
            props.unresolve(key);
        }
        else {
            console.warn("please set unresolve callback on log")
        }
        e.stopPropagation()
    }

    const parseText = (text) => {
        const lines = text.split(/\r?\n/);

        return lines.map( (line, index) => 
        {
            let lineLowerCase = line.toLowerCase()
            const warning = lineLowerCase.includes('warn')
            const error = lineLowerCase.includes('error')

            return (
                <div key={'line_' + index} className={`log-line ${index % 2 && "even "} ${warning && "warning "} ${error && "error "} `}>
                    {line}
                </div>
            )
        }
        )
    }

    return (
        <div>
            <div className="log-button-row">
                <div className="log-button bg-red" onClick={(e) => setStatusUnresolved(e)}>
                    Unresolved
                </div>

                <div className="log-button bg-green" onClick={(e) => setStatusResolved(e)}>
                    Resolved
                </div>
            </div>
            <div className="log">
                {parseText(text)}
            </div>
        </div>
    )
}

export default Log;