
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

import './App.css';

import * as utils from './utils'

import { getLogDir, downloadLogs, updateLog } from './state/logs_slice'

import DirectoryBar from "./components/directory_bar"
import Log from "./components/log_data"
import LogMetadata from "./components/log_metadata";

const LogDisplay = () => {
    const [logs, setLogs] = useState([]);

    const [expandedIndex, setExpandedIndex] = useState(-1);

    const [folderData, setFolderData] = useState([])

    const [ logDirPrefix, setLogDirPrefix ] = useState('logs');

    let dispatch = useDispatch();

    const jsonStructure = useSelector( state => state.logsReducer.jsonStructure)

    const logData = useSelector( state => state.logsReducer.logs) 

    const loading = useSelector( state => state.logsReducer.loading)

    useEffect(() => {
        dispatch( getLogDir() )        
    }, []);
    

    useEffect(() => {
        // Metadata has all loaded
        selectLogFolder( logDirPrefix )

    }, [jsonStructure]);

    useEffect(() => {
        if( !loading ) {
            console.log("Logs loading complete, setting most recent...")
            setMostRecentLogs( logData )
        }
    }, [loading]);

    useEffect(() => {
        if( !loading ) {
            console.log("Updating list on change in log data...")
            setMostRecentLogs( logData )
        }
    }, [logData]);

    useEffect(() => {
        selectLogFolder( logDirPrefix )
    }, [logDirPrefix]);

    const goBack = () => {
        let prefix = logDirPrefix;
        let lastDir = prefix.substring(0, prefix.lastIndexOf('/'));

        setLogDirPrefix(lastDir)
    }

    const setMostRecentLogs = ( json, prefix = '' ) => {
        // Get most recent metadata
        const checkLog = filename => filename.includes('.txt')
        let keys = utils.findKeyInJson( json, prefix, checkLog )

        let objects = keys.map( item => { 
            return {
                key: item,
                filename: utils.parseFilenameFromKey(item)
            }
        } )

        let sorted = objects.sort( (a, b) => a.filename > b.filename ? -1 : 0 )
        let mostRecent = sorted.map( item => item.key )

        let logs = []

        for( const path of mostRecent) {
            let key = path               
            logs.push( { [key] :logData[key] } )
        }

        setLogs( logs )
    }

    const selectLogContainer = (index) => {
        if( expandedIndex === index ) {
            setExpandedIndex(-1)
        }
        else {
            setExpandedIndex(index)  
        }
    }

    const selectLogFolder = ( prefix ) => {

        if( prefix.split('.').pop() === 'txt') {
            console.log('clicked log file')
            return;
        }

        // Get the folder data to display initially
        if (jsonStructure === {}) {
            return
        }

        if (Object.keys(jsonStructure).length === 0 || !Object.keys(jsonStructure['logs']))
            return

        let bits = prefix.split('/');
        bits = bits.filter( item => item.length > 0 )
        
        let json = jsonStructure;
        for( const bit of bits ) {
            json = json[bit]
        }

        let keys = Object.keys(json)

        let dirNames = keys.filter(item => item !== 'metadata')

        console.log('prefix :', prefix)

        if( prefix === 'logs') {
            console.log("root dir")
            if( !loading ) {
                setMostRecentLogs( json, prefix )
            }
        }

        if( prefix.split('/')[1]?.length > 0 && prefix.split('/').length === 2 ) {
            console.log("company dir")

            // Download all logs for company
            // Get all company log keys
            const checkLog = filename => filename.includes('.txt')
            let logPaths = utils.findKeyInJson( json, prefix, checkLog )

            // TODO:: Check if the logs have already been downloaded
            // dispatch( downloadLogs( logPaths ))
            
            setMostRecentLogs( json, prefix )
        }

        if( prefix.split('/').length === 3 ) { 
            console.log("files dir")

            setFolderData([])

            let filenames = dirNames

            // let logs = []

            // for( const filename of filenames) {
            //     let key = prefix + '/' + filename                
            //     logs.push( { [key] :logData[key] } )
            // }
            // setLogs( logs )

            setMostRecentLogs( json, prefix )
            
            return
        }
        
        let folderData = []
        for (const name of dirNames) {

            console.log('json :', json)
            let metadata = json.metadata

            // Get the time from the name for most recent log


            // let modifiedDate = utils.parseS3TimeString(metadata.lastModified)

            folderData.push({
                name: name,
                // lastModified: modifiedDate,
                key: metadata.key
            })
        }

        setFolderData(folderData)
    }

    const displayLogFolders = () => {
        return folderData.map( item => 
            <div className="log-dir-tile" key={item.key + item.name + '-tile'} onClick={ () => {
                console.log('clicked')
                setLogDirPrefix( item.key + item.name )
                }
            }>
                <h3>
                    {item.name}
                </h3>
                {/* <div>
                    Most Recent {item.lastModified.toLocaleDateString('en-GB')} <br/> {item.lastModified.toLocaleTimeString('en-GB')}
                </div> */}
            </div>
        )
    }

    const resolveLog = (key) => {

        let metadata = {...logData[key].metadata}
        
        metadata.status = "resolved"
        metadata.checked = "admin"

        let data = logData[key].data

        dispatch( updateLog( key, data, metadata ) )

        setExpandedIndex(-1)
    }

    const unresolveLog = (key) => {
        let metadata = {...logData[key].metadata}
        
        metadata.status = "unresolved"
        metadata.checked = "admin"

        let data = logData[key].data

        dispatch( updateLog( key, data, metadata ) )

        setExpandedIndex(-1)
    }

    const  getBackgroudStyle = (log) => {
        let key = Object.keys(log)[0];

        let details = utils.parseDetailsFromKey(key)
        
        let metadata = log[key].metadata

        // if( !metadata.checked ) {
        //     return "border-yellow "
        // }

        if( metadata.status ) {
            if( metadata.status === "resolved") {
                return "border-green "
            }
            return "border-red "
        }

        return ""
    }

    return(
        <div>
            <div className="dir-path-container">
                <DirectoryBar currentDir={logDirPrefix} callback={setLogDirPrefix} />
            </div>


            <div className="log-dirs-container">
                {displayLogFolders()}
            </div>
            

            { loading ? 
                <div>
                    Loading...
                </div>
                :
                <div>
                    <h2>Most recent</h2>
                    <div className="log-feed">
                        {
                            logs.map( (item, index) => 
                            <div key={'log_' + index} className={"log-container "
                                // + ( getBackgroudStyle(item) )
                                + (index === expandedIndex ? "expanded" : "")}
                                    onClick={ () => selectLogContainer(index) }>
                                    {
                                        index === expandedIndex ?
                                            <Log log={item} resolve={resolveLog} unresolve={unresolveLog}/>
                                            :
                                            <LogMetadata log={item}/>
                                        }
                                </div>
                            )
                        }
                    </div>
                </div>

            }
        </div>
    )
}

export default LogDisplay