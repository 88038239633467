
import { configureStore } from '@reduxjs/toolkit'

import allCompaniesReducer from './all_companies_slice'
import logsReducer from './logs_slice'


export const store = configureStore({
    reducer: {
        allCompaniesReducer: allCompaniesReducer,
        logsReducer: logsReducer,
    }
})
