import * as utils from '../utils'

const LogMetadata = (props) => {
    const log = props.log
    let key = Object.keys(log)[0];

    let details = utils.parseDetailsFromKey(key)
    
    let metadata = log[key].metadata
    let date = utils.parseDateFromKey(key) + " " + utils.parseTimeFromKey(key)

    let checked = metadata.checked

    let status = metadata.status

    return (
        <div className={"log-metadata "}>
            <div>
                {details.company}
            </div>
            <div>
                {details.location}
            </div>
            <div>
                {metadata.user}
            </div>
            <div>
                {date}
            </div>

            {
                status ?
                <div>
                    Marked <span className={status === "resolved" ? "green" : "red"} style={{fontWeight:'bold'}}>{status}</span> by {checked}
                </div>
                :
                <div>
                    Not checked yet
                </div>
            }
        </div>
    )
}

export default LogMetadata