import { createSlice } from '@reduxjs/toolkit'

import * as api from '../api'

const allCompaniesSlice = createSlice({
	name: 'allCompaniesSlice',
    initialState: {},
    reducers: {
		downloadedAllCompanyData(state, action) {
			state.data = action.payload
		},
    }
})

export const getCompanies = (bucket, location) => async (dispatch) => {
	try {
		const companiesData = await api.downloadCompaniesFile() 

		dispatch( downloadedAllCompanyData( companiesData ) )
	}
	catch (err) {
		console.error(err)
	}
}

export const { downloadedAllCompanyData } = allCompaniesSlice.actions
export default allCompaniesSlice.reducer;