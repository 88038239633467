

const DirectoryBar = ( props ) => {
    let logDirPrefix = props.currentDir
    let callback = props.callback

    let buttons = logDirPrefix.split('/').map((item) => {
        let str = logDirPrefix.substring(0, logDirPrefix.lastIndexOf(item)) + item
        return (
            <div className="dir-path-button" onClick={() => callback(str)}>
                {item}
            </div>
        )
    } ) 

    // Insert '/'s
    let buttonsWithSeperators;
    let seperator = <div> / </div>
    buttonsWithSeperators = buttons.reduce((arr, buttonsWithSeperators) => [...arr, buttonsWithSeperators, seperator], []);

    // Remove the last one
    buttonsWithSeperators.pop()

    return buttonsWithSeperators;
}

export default DirectoryBar;