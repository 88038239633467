
import LogDisplay from "./log_display";

const Dashboard = () => {
    return(
        <div>
            <LogDisplay/>
        </div>
    )
}

export default Dashboard;